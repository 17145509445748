import React from "react"
import { graphql } from "gatsby"
import SEO from "../components/seo"
import Layout from "../components/layout"
import BackgroundImage from 'gatsby-background-image'


class PageTemplate extends React.Component {

	componentDidMount = () => {
		
		if (!window.innerWidth < 767) {

			boxSizes();

			window.addEventListener('resize', function(){
				boxSizes();
			});

		}

		document.querySelector('.scroll').addEventListener('click', function () {
			document.querySelector('.content').scrollIntoView({ behavior: 'smooth'});
		});

	}

    render() {

		const pathname = this.props.location.pathname;
		const page = this.props.data.wordpressPage;

		return (

			<Layout pageName={page.title} additionalClass={page.title}>

				<SEO title={page.yoast.title} description={page.yoast.metadesc} pathname={pathname} />

				<div className="hero">

					<div className="container">

						{page.acf.heading ? 
							<h1>{page.acf.heading}</h1> 
						: 
							<h1>{page.title}</h1> 
						}

						{page.acf.subheading ? 
							<h2>{page.acf.subheading}</h2> 
						: null}
								
					</div>

					<div className="arrow-wrap">
						<span className="scroll icon-chevron-thin-down"></span>
					</div>

				</div>

				<div id="swirl" className="swirl"></div>
								
				<div className="content">

					{page.acf.body_copy ?

						<div className="container" dangerouslySetInnerHTML={{ __html: page.acf.body_copy }} />
				
					: null }
				
					{page.acf.block ?

						<div>

						{page.acf.block.map((node, index) =>

							<div key={index} className="block">
								
								<div className="text">
									<h2 className="title">{node.title}</h2>
									<div className="content_inner" dangerouslySetInnerHTML={{ __html: node.content }} />
								</div>

								{node.image.localFile.extension === 'svg' ?
									<img className="image" src={node.image.localFile.publicURL} alt={node.image.localFile.name} />
								: 
									<BackgroundImage
										className="image"
										fluid={node.image.localFile.childImageSharp.fluid}
									/>
								}
								
								<div className="spacer"></div>
								
							</div>
						)}

						</div>

					: null}

				</div>


			</Layout>

		)
	}
	  
}

export default PageTemplate

export const pageQuery = graphql`
	query($id: String!) {
		wordpressPage(id: { eq: $id }) {
			title
			content
			acf {
				heading
				subheading
				body_copy
				portfolio_selector {
					post_name
					acf {
						featured_image {
							localFile {
								childImageSharp {
									fluid(maxWidth: 3000) {
										...GatsbyImageSharpFluid
									}
								}
							}
						}
					}
				}
				block {
					title
					content
					image {
						localFile {
							childImageSharp {
								fluid(maxWidth: 3000) {
									...GatsbyImageSharpFluid_withWebp_tracedSVG
								}
							}
						extension
						publicURL							
						}
					}
				}
			}
			yoast{
				title
				metadesc
			}
		},
		fileName: file(relativePath: { eq: "white-wave-bg.png" }) {
			...fluidImage
		},
	},
`
function boxSizes() {

	if (document.querySelector('.grid')) {
		var c = document.querySelector(".grid").childNodes;
		c[0].classList.add("small");
		c[1].classList.add("small");
		c[2].classList.add("small");
		c[3].classList.add("tall");
		c[4].classList.add("long");
		c[5].classList.add("small");
		c[6].classList.add("small");
		c[7].classList.add("long");
		c[8].classList.add("small");

		var small = document.querySelectorAll('.item.small');
		var long = document.querySelectorAll('.item.long');
		var tall = document.querySelectorAll('.item.tall');

		small.forEach(function(square) {

			square.style.height = square.offsetWidth + "px";

			long.forEach(function(longHeight) {
				longHeight.style.height = square.clientHeight + "px";
			});

			tall.forEach(function(tallHeight) {
				tallHeight.style.height = (square.clientHeight * 2 + 15) + "px";
			});

		});

	}

}